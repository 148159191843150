import React from "react";
import "./About.css";

import greenPark from "../../media/green_park.jpg";
import greenSpace from "../../media/green-space.jpg";
import greenTownShip  from "../../media/green-township.jpg"
const About = () => {
  return (
    <div className="aboutContainer">
      <div className="aboutHeader">
        <h1>GREEN LIVING CONCEPT</h1>
        <p>
          Wave City is "World's Largest Green Pre-Certified Platinum Rated
          Township". The project makes use of a rain water harvesting system as
          well as water recycling plants that recycle grey water for daily use
          and ensure a minimum strain on the ecosystem.
        </p>
      </div>
      <div className="aboutCardContainer">
        <div className="aboutCardContent">
          <h1>Clean & Green Neighborhood Parks And A Vast Central Park</h1>
          <p>
            A vast green space with clean & green neighborhood parks and a
            central park create a serene and nurturing environment to promote
            public health and quality of life. The concept aims at conserving
            and preserving the resources that the city uses. Smart systems for
            water, electricity, sewage and transport make it an advanced
            lifestyle reducing the consumption of water, electricity via
            optimization & fuel consumption with live time traffic updates.
          </p>
        </div>
        <div className="aboutCardImage">
          <img src={greenPark} alt="Green Park" />
        </div>
      </div>
      <div className="aboutCardContainer">
        <div className="aboutCardContent">
          <h1>Vast Green Spaces</h1>
          <p>
            Consumption and reducing operating costs for businesses, schools,
            hospitals and residents are just a few of the systems in place at
            Wave City. This will also have waste water treatment infrastructure
            to treat the waste water generated within the project.
          </p>
        </div>
        <div className="aboutCardImage">
          <img src={greenSpace} alt="Green Space" />
        </div>
      </div>
      <div className="aboutCardContainer">
        <div className="aboutCardContent">
          <h1>World's Largest Green Pre-Certified Platinum Rated Township</h1>
          <p>
            Wave City has been designed to address the space, traffic
            management, social and environmental issues. The Township has been
            evaluated on the following broad aspects:
          </p>
          <ul>
            <li>Environmental planning</li>
            <li>Land Use planning</li>
            <li>Resources management</li>
            <li>Community development</li>
          </ul>
        </div>
        <div className="aboutCardImage">
          <img src={greenTownShip} alt="Green Town" />
        </div>
      </div>
    </div>
  );
};

export default About;

import React, { useEffect, useState } from "react";
import EnquiryBanner from "../EnquiryBanner/EnquiryBanner";
import emailjs from "emailjs-com";
import "./Projects.css";

import downloadIcon from "../../media/downloadicon.png"

const Projects = (props) => {
  const initialForm = { name: "", phoneno: "", email: "", budget: "" };
  const [form, setForm] = useState(initialForm);
  const [isSubmit, setIsSubmit]=useState(false);
  const [user,setUser]=useState(JSON.parse(localStorage.getItem('user')))

  useEffect(()=>{
  },[user])

  const handleformChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    if(form.name!=="" &&  form.phoneno!==""&&form.email!==""){
      localStorage.setItem('user',true);
      emailjs.send("service_m7qr9ys","template_qdrl3ud", form,'jYQFRwAVwM6wKn-IX');
      setUser(JSON.parse(localStorage.getItem('user')))
      setForm(initialForm);
      setIsSubmit(!isSubmit)
    }
    
  };

  return (
    <div className="projectContainer">
      <EnquiryBanner />
      <div className="projectHeader">
        <h1>{props.heading}</h1>
        <p>{props.description}</p>
      </div>
      <div className="projectFeature">
        <div className="projectFeatureHeading">
          <h3>PROJECT AMENITIES</h3>
        </div>
        <div className="amenitieWrapper">
          {props.amenities.map((amenitie, i) => (
            <div key={i} className="amenitieContainer">
              <div className="amenitieImage">
                <img src={amenitie.img} alt="Amenitie Image" />
              </div>
              <div className="amenitieHeader">
                <h3>{amenitie.name.toUpperCase()}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="projectPriceBrochure">
        <div className="priceBrochureHeader">
          <h1>Price And Brochure</h1>
          <p>
            We celebrates very occasion with great discount every season. Helps
            Us to convey those offers to you by filling the details.
          </p>
        </div>
        <div className="formContainer">
          {!isSubmit && !user ? 
            <form className="formWrapper" onSubmit={handleClick}>
              <input
                type="text"
                name="name"
                placeholder="Share your name here.."
                onChange={handleformChange}
                value={form.name}
                required
              />
              <input
                type="number"
                name="phoneno"
                placeholder="Share your phone no here.."
                onChange={handleformChange}
                value={form.phoneno}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Share your email here.."
                onChange={handleformChange}
                value={form.email}
                required
              />
              <select name="budget" onClick={handleformChange} required>
                <option value="" selected>
                  Please Share your budget
                </option>
                <option value="10L-30L">10 lac - 30 lac</option>
                <option value="30L-60L">30 lac - 60 lac</option>
                <option value="60L-90L">60 lac- 90 lac</option>
                <option value="90L-1.2Cr">90lac - 1.2Cr</option>
                <option value="Above 1.2Cr">Above 1.2Cr</option>
              </select>
              <button type="submit" >
                Submit
              </button>
            </form>
          : 
            <div className="downloadButtonContainer">
              <a href={props.price} download={`PriceList'+${props.name}`} target="_blank" className="downloadContainer">
                <button>Download Price List</button>
                <img src={downloadIcon} alt="Downlaod icon"/>
              </a>
              <a href={props.brochure} download={`Brochure'+${props.name}`} target="_blank" className="downloadContainer">
                <button>Download Brochure</button>
                <img src={downloadIcon} alt="Downlaod icon"/>
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Projects;


import { Route, Routes } from 'react-router-dom';


import './App.css';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer'
import About from './components/About/About';
import Eligo from './components/Projects/Eligo';
import Veridia from './components/Projects/Veridia';
import Floor from './components/Projects/Floor';
import Plots from './components/Projects/Plots';
import Contact from './components/Contact/Contact';


function App() {

  window.onload = window.localStorage.clear();
  return (
    <div className="App">
          <Navbar/>
       <Routes>
          <Route path='/' exact  element={<Home/>}/>
          <Route path='/aboutwavecity' exact element={<About/>}/>
          <Route path='/projectwaveveridia' exact element={<Veridia/>}/>
          <Route path='/projectwavefloor' exact element={<Floor/>}/>
          <Route path='/projectwaveplots' exact element={<Plots/>}/>
          <Route path='/projectwaveeligo' exact element={<Eligo/>}/>
          <Route path='/contactUs' exact element={<Contact/>}/>
       </Routes>
          <Footer/>
    </div>
  );
}

export default App;

import React from 'react'

import "./Footer.css"

const Footer = () => {
  return (
    <div className='footer-container'>
         <span>© This is not an official website. It is just for marketing pupose, maintained by RERA Registered Agent.| Brij Kumar: UPRERAAGT14654.</span>
        <p>
        Disclaimer:RERA Regsitered Agent shall neither be responsible nor liable for any inaccuracy in the information provided herein, hence customers are requested to verify the information from the developers concerned before taking their decision to purchase the properties. RERA Regsitered Agent will not be liable for any action, cost / expense / loss, payment transaction by any person / entity based on the information provided on the Portal.
        </p>
    </div>
  )
}

export default Footer
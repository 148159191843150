import React from "react";
import Projects from "./Projects";

import familyIcon from "../../media/family_icon.png"
import gameArea from "../..//media/gameing-room.png"
import powerIcon from "../..//media/power-backup.png"
import gymIcon from "../..//media/gym_icon.png"
import jioIcon from "../..//media/jioicon.png"
import ultraIcon from "../..//media/ultra-modern-infrastructure.png"
import G2Icon from "../..//media/G2-structure.png"
import yogaIcon from "../..//media/yogaIcon.png"
import greenIcon from "../../media/largeopen-green-space.png"

const Floor = () => {


    const amenities=[
        {
            name:"clubhouse",
            img:familyIcon
        },
        {
            name:"yoga",
            img:yogaIcon
        },
        {
            name:"gym",
            img:gymIcon
        },
        {
            name:"jio fiber connnectivity",
            img:jioIcon
        },
        {
            name:"kids play area",
            img:gameArea
        },
        {
            name:"power backup",
            img:powerIcon
        },
        {
            name:"large and open green space",
            img:greenIcon
        },
        {
            name:"ultra modern infrastructure",
            img:ultraIcon
        },
        {
            name:"G+2 structure",
            img:G2Icon
        }
        
    ]


  return (
    <>
   
    <Projects
      heading="Floors"
      description="Comfort, convenience, and privacy are all hallmarks of Wave Floors. Wave Floors delivers more than one can envision with a spectrum of exquisite 2 & 3 BHK, G+2 independent residences. It's a destination to live freely, peacefully, and autonomously in your private independent haven that ranges from 53.69 sq. m. to 125.17 sq. m. in size of carpet area. The residents on the second floor of Wave Floors can redefine the grandeur of living and make their stay at Wave Floors a pleasure because they all have unique *terrace usage rights!"
      amenities={amenities}
      price=""
      brochure=""
    />
    </>
  );
};

export default Floor;
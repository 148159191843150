import React from "react";
import Projects from "./Projects";

import familyIcon from "../../media/family_icon.png"
import gameArea from "../..//media/gameing-room.png"
import gameArea1 from "../..//media/gameing-room-1.png"
import gymIcon from "../..//media/gym_icon.png"
import jioIcon from "../..//media/jioicon.png"
import multipurposeHallIcon from "../..//media/multipurpose-hall.png"
import restaurantIcon from "../..//media/restaurant-icon-1.png"
import yogaIcon from "../..//media/yogaIcon.png"


const Eligo = () => {


    const amenities=[
        {
            name:"clubhouse",
            img:familyIcon
        },
        {
            name:"yoga",
            img:yogaIcon
        },
        {
            name:"gym",
            img:gymIcon
        },
        {
            name:"jio fiber connnectivity",
            img:jioIcon
        },
        {
            name:"multipurpose hall",
            img:multipurposeHallIcon
        },
        {
            name:"restaurant",
            img:restaurantIcon
        },
        {
            name:"kids play area",
            img:gameArea
        },
        {
            name:"indoor game area",
            img:gameArea1
        },
        
    ]


  return (
    <>
   
    <Projects
      heading="Eligo"
      description="Eligo is all about finding the right balance in life and striking the equilibrium of living with the luxuries of the modern world right on the lap of nature. Eligo 3 BHK Urbane apartments enable you to live a perfectly balanced life with meticulously planned, sustainable infrastructure, ample amenities set amidst lush greenery and water bodies and equipped with world-class safety features for a blissful living. These luxurious apartments are strategically located on NH-24, offering uber-spacious living spaces with well-ventilated rooms and 7 feet depth balcony that gives you an uninterrupted view of podium greens and central park spread over 6.5 acres. All the bedrooms have attached bathrooms and there’s also a provision of home automation system along with video door phone. The residents of Eligo would also get access to Club Executive, our lavish clubhouse."
      amenities={amenities}
      price=""
      brochure=""
    />
    </>
  );
};

export default Eligo;

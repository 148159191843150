import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Home.css";

import command from "../../media/command.jpg";
import security from "../../media/security.jpg";
import jio from "../../media/jio.webp";
import air from "../../media/air.webp";
import water from "../../media/water.jpg";
import solid from "../../media/solid.jpeg";
import EnquiryBanner from "../EnquiryBanner/EnquiryBanner";

const Home = () => {
   return (
    <div className="home-container">
       <EnquiryBanner/>
      <div className="intro-container">
        <h3>Welcome to Wave City,</h3>
        <p>
          Wave City is one of Ghaziabad’s most coveted Smart City, nestled
          adjacent to NH-24. This project is incorporated to accommodate a
          lifestyle that encapsulates a better experience than living in a city.
          A life immersed in sheer greenery, the shared compassion of society,
          and the luxury of time. Wave City offers a wide array of products
          ranging from independent floors, low and mid-rise apartments,
          affordable housing units, luxurious villas and apartments, to
          commercial properties and office spaces. Our every product ensures the
          pinnacle of fine living with adherence to international standards, and
          the amalgamation of modern-day luxuries and amenities.
        </p>
        <p>
          Come, discover the smart city that boasts soaring high ceilings, grand
          bedrooms, superior modular kitchens, stylish angles, and quality
          fittings for an unrivaled lifestyle. The city also offers a few smart
          features like Fiber Optic Connectivity, 24X7 CCTV surveillance,
          Automated Water Management, Street Light Automation, Waste Management,
          Central Command Centre, and Smart Metering System.
        </p>
      </div>
      <div className="features-container">
        <h3>Wave City Features..</h3>
        <div className="features-wrapper">
           <div className="feature-container">
              <img src={command} alt="Command Center Image"/>
              <h3>Central Command Center</h3>
           </div>
           <div className="feature-container">
              <img src={security} alt="Homeland Security Management"/>
              <h3>Homeland Security Management</h3>
           </div>
           <div className="feature-container">
              <img src={jio} alt="Jio  Fiber Image"/>
              <h3>Jio Fiber Connectivity</h3>
           </div>
           <div className="feature-container">
              <img src={water} alt="Water Management System"/>
              <h3>Water Management System</h3>
           </div>
           <div className="feature-container">
              <img src={solid} alt="Solid Management System"/>
              <h3>Solid Waste Management</h3>
           </div>
           <div className="feature-container">
              <img src={air} alt="Air quality image"/>
              <h3>Air Quality</h3>
           </div>
           <div className="btn">
           <button><Link to="/aboutwavecity">Explore More...</Link></button>
           </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

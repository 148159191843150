import React from 'react'
import NavbarContent from "./NavbarContent"
import "./Navbar.css"

const Navbar = () => {
  return (
    <nav className='navbar-container'>
        <div className='sideLayer_Navbar'>
             <hr></hr>
        </div>
        <div className='middleLayer_Navbar'>
             <NavbarContent/>
        </div>
        <div className='sideLayer_Navbar'>
            <hr></hr>
        </div>
    </nav>
  )
}

export default Navbar
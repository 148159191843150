import React, { useState,useEffect } from 'react'
import emailjs from "emailjs-com";

import "./EnquiryBanner.css"

const EnquiryBanner = () => {
    const intialForm = { name: "", phoneno: "", email: "", budget:"",query: "" };
    const [enquiryForm, setEnquiryForm] = useState(intialForm);

    const [user,setUser]=useState(JSON.parse(localStorage.getItem('user')))

    
  useEffect(()=>{
    setUser(JSON.parse(localStorage.getItem('user')))
      },[user])

    const handleFormChange = (e) => {
      setEnquiryForm({ ...enquiryForm, [e.target.name]: e.target.value });
    };

    const handleSubmit=(e)=>{
      e.preventDefault();
        localStorage.setItem('user',true);
        console.log(enquiryForm);
        emailjs.send("service_m7qr9ys","template_qdrl3ud", enquiryForm,'jYQFRwAVwM6wKn-IX');
        setEnquiryForm(intialForm)
    }

  return (
    <div className="enquiry-container">
        <div className="enquiry-text">
          <div className="enquiry-heading">
            <h1>GET YOUR BEST OFFER</h1>
            <h3>Guaranteed Lowest Price</h3>
          </div>
          <div className="enquiry-subheading">
            <h4>We are just Click or Call Away</h4>
            <h6>You just give your query.. We Promise in Best..</h6>
            <p>Best in Location, Best in Price , Best in Service ..</p>
          </div>
          <span className="enquiry-banner">
            CALL US: 9999534995, 7906598288, 9568629085
          </span>
        </div>
        <div className="enquiry-form">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={enquiryForm.name}
              onChange={handleFormChange}
              placeholder="Full Name"
              required
            />
            <input
              type="text"
              name="email"
              value={enquiryForm.email}
              onChange={handleFormChange}
              placeholder="Email Name"
              required
            />
            <input
              type="text"
              name="phoneno"
              value={enquiryForm.phoneno}
              onChange={handleFormChange}
              placeholder="Phone Number"
              required
            />
             <select name="budget" onClick={handleFormChange} required>
                <option value="" selected>
                  Please Share your budget
                </option>
                <option value="10L-30L">10 lac - 30 lac</option>
                <option value="30L-60L">30 lac - 60 lac</option>
                <option value="60L-90L">60 lac- 90 lac</option>
                <option value="90L-1.2Cr">90lac - 1.2Cr</option>
                <option value="Above 1.2Cr">Above 1.2Cr</option>
              </select>
            <textarea
              rows="5"
              name="query"
              value={enquiryForm.query}
              onChange={handleFormChange}
              placeholder="Query...."
              required
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
  )
}

export default EnquiryBanner
import React from "react";
import EnquiryBanner from "../EnquiryBanner/EnquiryBanner";
import "./Contact.css";


import locationIcon from "../../media/locationIcon.png"
import phoneIcon from "../../media/phoneIcon.png"
import mailIcon from "../../media/mailIcon.png"

const Contact = () => {
  return (
    <section className="contactContainer">
      <div className="contactHeader">
        <h1>Contact Us</h1>
      </div>
     <div className="contactAdress">
          <img src={locationIcon} alt="Location Icon" height='35px' width='35px'/>
          <p>
            Shop No.2 , Plot No. 2 Sector 2, Wave City, NH 24 Ghaziabad Uttar
            Pradesh 201015
          </p>
        </div>
        <div className="contactPhone">
          <img src={phoneIcon} alt="Phone Icon" height='35px' width='35px'/>
          <p>+91 9999534995</p>
          <p>+91 7906598288</p>
          <p>+91 9568629085</p>
        </div>
        <div className="contactEmail">
           <img src={mailIcon} alt="Mail Icon" height='35px' width='35px'/>
          <p>
            infowavecitynh24@gmail.com
          </p>
        </div>
        <EnquiryBanner/>
    </section>
  );
};

export default Contact;

import React from "react";
import Projects from "./Projects";

import familyIcon from "../../media/family_icon.png"
import gameArea from "../..//media/gameing-room.png"
import powerIcon from "../..//media/power-backup.png"
import gymIcon from "../..//media/gym_icon.png"
import jioIcon from "../..//media/jioicon.png"
import multipurposeHallIcon from "../..//media/multipurpose-hall.png"
import restaurantIcon from "../..//media/restaurant-icon-1.png"
import yogaIcon from "../..//media/yogaIcon.png"
import greenIcon from "../../media/largeopen-green-space.png"

const Plots = () => {


    const amenities=[
        {
            name:"clubhouse",
            img:familyIcon
        },
        {
            name:"yoga",
            img:yogaIcon
        },
        {
            name:"gym",
            img:gymIcon
        },
        {
            name:"jio fiber connnectivity",
            img:jioIcon
        },
        {
            name:"multipurpose hall",
            img:multipurposeHallIcon
        },
        {
            name:"restaurant",
            img:restaurantIcon
        },
        {
            name:"kids play area",
            img:gameArea
        },
        {
            name:"power backup",
            img:powerIcon
        },
        {
            name:"large and open green space",
            img:greenIcon
        }
        
    ]


  return (
    <>
   
    <Projects
      heading="Plots"
      description="Wave City welcomes you to a life that is truly spectacular and molds your dreams into reality with exquisite offerings. Wave City features plots that empower you to construct a luxurious house as per your aspirations. Come and select from a wide range of well-crafted plots varying in size from 135 Sq. yd., 194 Sq. yd., 287 Sq. yd., 325 Sq. yd., to 404 Sq. yd. and above. Wave City residential plots come with a promise to give you a life beyond extraordinary and are bestowed with various world-class amenities. These plots present a perfect investment opportunity that liberates you to construct your dream adobe amidst the city’s lush greenery and ready-to-use exceptional infrastructure."
      amenities={amenities}
      price=""
      brochure=""
    />
    </>
  );
};

export default Plots;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../media/wave-logo.png";

import "./NavbarContent.css";

const NavbarContent = () => {
  let [screenSize, setScreenSize] = useState(window.innerWidth);
  let [toggle, setToggle] = useState(false);
  useEffect(() => {
    function handleWindowResize() {
      setScreenSize(() => window.innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      {screenSize <= 480 ? (
        <div className="navBar-content">
          <div className="navBar-content-left">
           <Link  onClick={() => setToggle(toggle ? !toggle: toggle)} to="/"><img src={logo} alt="Logo" /></Link> 
          </div>
          <div className="navBar-content-right">
            {!toggle ? (
              <div
                className="navBar-content-right-sm"
                onClick={() => setToggle(!toggle)}
              >
                <div className="navBar-toggle"></div>
                <div className="navBar-toggle"></div>
                <div className="navBar-toggle"></div>
              </div>
            ) : (
              <div
                className="navBar-content-right-sm"
                onClick={() => setToggle(!toggle)}
              >
                <div className="navBar-toggle-cross45"></div>
                <div className="navBar-toggle-cross225"></div>
              </div>
            )}
          </div>
          <div
            style={{ display: !toggle ? "none" : "block" }}
            className="toggleMenu"
          >
            <div className="toggleListName">
               <Link to="/aboutwavecity" onClick={()=> setToggle(!toggle)}><li>About WaveCity</li></Link>
            </div>
            <div className="toggleListName">
               <Link to="/projectwaveveridia"  onClick={()=> setToggle(!toggle)}><li>Wave Veridia</li></Link>
            </div>
            <div className="toggleListName">
               <Link to="/projectwavefloor" onClick={()=> setToggle(!toggle)}><li>Wave Floors</li></Link>
            </div>
            <div className="toggleListName">
               <Link to="/projectwaveplots" onClick={()=> setToggle(!toggle)}><li>Wave Plots</li></Link>
            </div>
            <div className="toggleListName">
               <Link to="/projectwaveeligo" onClick={()=> setToggle(!toggle)}><li>Wave Eligo</li></Link>
            </div>
            <div className="toggleListName">
               <Link to="/contactUs" onClick={()=> setToggle(!toggle)}><li>Contact Us</li></Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="navBar-content">
          <div className="navBar-content-left">
           <Link to="/"><img src={logo} alt="Logo" /></Link> 
          </div>
          <div className="navBar-content-right">
            <div className="navBar-content-right-lg">
              <ul>
                <Link  to="/aboutwavecity">
                  <li>
                    About <span>WaveCity</span>
                  </li>
                </Link>
                <Link to="/projectwaveveridia">
                  <li>
                    Wave <span>Veridia</span>
                  </li>
                </Link>
                <Link to="/projectwavefloor">
                  <li>
                    Wave <span>Floor</span>
                  </li>
                </Link>
                <Link to="/projectwaveplots">
                  <li>
                    Wave <span>Plots</span>
                  </li>
                </Link>
                <Link to="/projectwaveeligo">
                  <li>
                    Wave <span>Eligo</span>
                  </li>
                </Link>
                <Link to="/contactUs">
                  <li>
                    Contact <span>Us</span>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NavbarContent;

import React from "react";
import Projects from "./Projects";

import familyIcon from "../../media/family_icon_1.png"
import gymIcon from "../..//media/gym_icon_1.png"
import lawnIcon from "../..//media/lawn_icon.png"
import joggingIcon from "../..//media/jogging_icon.png"
import restaurantIcon from "../..//media/restaurant_bar_icon.png"
import swimmingIcon from "../..//media/swimming_pool_icon.png"


const Veridia = () => {


    const amenities=[
        {
            name:"clubhouse",
            img:familyIcon
        },
        {
            name:"swimming pool",
            img:swimmingIcon
        },
        {
            name:"gym",
            img:gymIcon
        },
        {
            name:"party lawn",
            img:lawnIcon
        },
        {
            name:"jogging track",
            img:joggingIcon
        },
        {
            name:"restaurant and bar",
            img:restaurantIcon
        },
        
    ]


  return (
    <>
   
    <Projects
      heading="Veridia"
      description="Veridia is an ultra-luxurious residency nestled on a 57-meter wide road amidst sprawling lush greens that never fails to mesmerize. The project boasts thoughtful layouts, immaculate design, and ultra-modern amenities that set it apart from any other property on NH24. These homes illustrate the flawless blend of nature and science with the provision of a home automation system, front door digital lock, and video door phone. Apart from that EV charging point is also available at every car parking. Veridia presents 5 BHK Sky Villas that ooze extravagance with a grand double-height ceiling and offers all the modern-day luxuries that exceed your aspirations. Our lavish 4 BHK floors offer chic living space, 4 grand bedrooms, and modern fittings throughout, there is nothing dated about these homes. Built for the contemporary urban dweller, every home offers the best in style, design, comfort, and luxury."
      amenities={amenities}
      price=""
      brochure=""
    />
    </>
  );
};

export default Veridia;